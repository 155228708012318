import React, { useState, useEffect, useRef } from "react";

import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Alert from "react-bootstrap/Alert";
import Container from "react-bootstrap/Container";
import InputGroup from "react-bootstrap/InputGroup";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import FormControl from "react-bootstrap/FormControl";

import axios from "axios";
import * as XLSX from "xlsx";

import authHeader from "../../auth-header";
import OwnerSelect from "../../components/ownerSelect";

const InboundSearch = (props) => {
  const [results, setResults] = useState([]);
  const [searchState, setSearchState] = useState(0);
  const [owner, setOwner] = useState("");
  const [searchType, setSearchType] = useState(0);
  const searchRef = useRef();
  const tableRef = useRef();
  const startDateRef = useRef();
  const endDateRef = useRef();

  const [searchItems, setSearchItems] = useState([
    { title: "All", text: "All Fields", field: "" },
    { title: "Part Number", text: "Part Number", field: "PartNum" },
    { title: "ALU PO Numberrr", text: "ALU PO Number", field: "ALUPONum" },
    { title: "Customer PO Number", text: "Customer PO Number", field: "PONum" },
    { title: "SO Number", text: "SO Number", field: "SONum" },
    { title: "Serial #", text: "Serial #", field: "serialNum" },
  ]);

  useEffect(() => {
    if (props.customer === "34") {
      setSearchItems([
        { title: "All", text: "All Fields", field: "" },
        { title: "Part Number", text: "Part Number", field: "PartNum" },
        { title: "ALU PO Numberrr", text: "ALU PO Number", field: "ALUPONum" },
        {
          title: "Customer PO Number",
          text: "Customer PO Number",
          field: "PONum",
        },
        { title: "SO Number", text: "SO Number", field: "SONum" },
        { title: "Serial #", text: "Serial #", field: "serialNum" },
      ]);
    } else if (props.customer === "41") {
      setSearchItems([
        { title: "All", text: "All Fields", field: "" },
        { title: "Part Number", text: "Part Number", field: "PartNum" },
        { title: "ALU PO Number", text: "ALU PO Number", field: "ALUPONum" },
        {
          title: "Customer PO Number",
          text: "Customer PO Number",
          field: "PONum",
        },
        { title: "SO # / Bin #", text: "SO # / Bin #", field: "SONum" },
        { title: "Material ID", text: "Material ID", field: "serialNum" },
      ]);
    } else {
      setSearchItems([
        { title: "All", text: "All Fields", field: "" },
        { title: "Part Number", text: "Part Number", field: "PartNum" },
        { title: "Vendor", text: "Vendor", field: "Vendor" },
        {
          title: "Customer PO Number",
          text: "Customer PO Number",
          field: "PONum",
        },
        { title: "SO Number", text: "SO Number", field: "SONum" },
        { title: "Serial #", text: "Serial #", field: "serialNum" },
      ]);
    }
  }, [props.customer]);

  const totalQuantity = () => {
    return results.reduce((sum, cur) => sum + parseInt(cur.Quantity), 0);
  };

  const searchHandler = (e) => {
    e.preventDefault();
    setSearchState(1);
    setResults([]);
    axios
      .get("/api/search/inbound", {
        params: {
          owner: owner,
          startDate: startDateRef.current.value,
          endDate: endDateRef.current.value,
          value: searchRef.current.value,
          field: searchItems[searchType].field,
          customer: props.customer,
        },
        headers: authHeader(),
      })
      .then(function (response) {
        setResults(response.data);
        if (response.data.length > 0) setSearchState(0);
        else setSearchState(2);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const exportDataHandler = () => {
    const table_elt = tableRef.current;

    const workbook = XLSX.utils.table_to_book(table_elt, {
      dateNF: "yyyy-MM-DD hh:mm:ss",
    });

    XLSX.writeFile(workbook, "Report_Inbound.xlsx");
  };

  const SearchResults = () => (
    <Table ref={tableRef} responsive size="sm">
      <thead className="table-light">
        <tr>
          <th>Date</th>
          <th>Owner</th>
          <th>SKU</th>
          <th>Part #</th>
          <th>{props.customer === "41" ? "SO # / Bin #" : "SO #"}</th>
          <th>{props.customer === "34" ? "ALU #" : "Vendor"}</th>
          <th>{props.customer === "41" ? "Material ID" : "Serial #"}</th>
          <th>Description</th>
          <th>Checked By</th>
          <th>Stock</th>
        </tr>
      </thead>
      <tbody>
        {results.map((item) => (
          <tr key={item.InboundID}>
            <td>{item.DateRCVD} </td>
            <td>{item.Owner} </td>
            <td>{item.SKUNum} </td>
            <td>{item.PartNum}</td>
            <td>{item.SONum} </td>
            <td>{item.ALUPONum} </td>
            <td>{item.SerialNum} </td>
            <td>{item.Description} </td>
            <td>{item.CheckedBy} </td>
            <td>{item.Quantity} </td>
          </tr>
        ))}
      </tbody>
      <tfoot>
        <tr>
          <td colSpan="7" />
          <th>Total</th>
          <th>{totalQuantity()}</th>
        </tr>
      </tfoot>
    </Table>
  );

  return (
    <>
      <Container fluid>
        <h4 style={{ backgroundColor: "#F0F0F0", padding: 5 }}>Inbound Search</h4>
        <Form onSubmit={searchHandler}>
          <Row className="mb-3">
            <Form.Group as={Col} xl={2} lg={3} md={4}>
              <Form.Label>Owner</Form.Label>
              <OwnerSelect selectAll={true} setOwnerHandler={setOwner} customer={props.customer} />
            </Form.Group>
            <Form.Group as={Col} xl={2} lg={3} md={4}>
              <Form.Label>From</Form.Label>
              <FormControl type="date" ref={startDateRef} />
            </Form.Group>
            <Form.Group as={Col} xl={2} lg={3} md={4}>
              <Form.Label>To</Form.Label>
              <FormControl type="date" ref={endDateRef} />
            </Form.Group>
            <Form.Group as={Col} className="align-self-end" xl={4} lg={5} sm={8}>
              <InputGroup>
                <DropdownButton onSelect={(e) => setSearchType(e)} variant="outline-secondary" title={searchItems[searchType].title} id="input-group-dropdown-1">
                  {searchItems.map((item, i) => (
                    <Dropdown.Item key={i} eventKey={i}>
                      {item.text}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
                <Form.Control type="text" ref={searchRef} />
                {searchState !== 1 ? (
                  <Button type="submit">Search</Button>
                ) : (
                  <Button>
                    <Spinner animation="border" size="sm" />
                  </Button>
                )}
              </InputGroup>
            </Form.Group>
            <Col className="align-self-end">
              <Button variant="outline-primary" onClick={exportDataHandler}>
                Save Data (.xlsx)
              </Button>
            </Col>
          </Row>
        </Form>
        {searchState === 2 && <Alert variant="danger">No Results</Alert>}
        {/* {results.length > 0 && <Results columns={columns} data={results} />} */}
        {results.length > 0 && <SearchResults />}
      </Container>
    </>
  );
};

export default InboundSearch;
